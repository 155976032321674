import MultiSelect from  'react-multiple-select-dropdown-lite'
import  'react-multiple-select-dropdown-lite/dist/index.css'

const Header = ({ caseData, caseFilters, handleFilterOnChange }) => {
    const options = [...new Set(caseData.map(c => c.case))].map(c => ({
        label: c,
        value: c,
        title: 'Case'
    }))
    return (
        <header className="header">
            <MultiSelect
                placeholder="Filter by case..."
                onChange={handleFilterOnChange}
                disableChip
                chipAlternateText={`case${caseFilters.length > 1 ? 's' : ''} selected`}
                options={options}
            />
        </header>
    )
}

export default Header
