import Spinner from "react-loader-spinner";

const Loader = () => {
    return (
        <div className="loader">
            <Spinner
                className="loader"
                type="Rings"
                color="#f44336"
                height={100}
                width={100}
            />
            <h3>Preparing map...</h3>
        </div>
    )
}
export default Loader
