import { Icon } from '@iconify/react'
import locationIcon from '@iconify/icons-mdi/circle'

const LocationMarker = ({ lat, lng, chroma, isSelected, onClick }) => {
    return (
        <div className="location-marker" onClick={onClick}>
            <Icon
                icon={locationIcon}
                className={`location-icon ${isSelected ? 'active': ''}`}
                style={{
                    color: chroma.hex()
                }}
            />
        </div>
    )
}

export default LocationMarker
