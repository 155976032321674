import { Icon } from '@iconify/react'
import closeIcon from '@iconify/icons-mdi/close'

const LocationInfoBox = ({ info, handleClose }) => {
    return (
        <div className="location-info">
            <h3>Covid 19 Case Info</h3>
            <ul>
                <li>Case: <strong>{ info.case }</strong></li>
                <li>Date: <strong>{ info.date }</strong></li>
            </ul>
            <Icon icon={closeIcon} className="close-icon" onClick={handleClose} />
        </div>
    )
}

export default LocationInfoBox
