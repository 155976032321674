import { useState, useEffect } from 'react'
import Map from './components/Map'
import Loader from './components/Loader'
import Header from './components/Header'

function App() {
  const [caseData, setCaseData] = useState([])
  const [loading, setLoading] = useState(false)
  const [caseFilters, setCaseFilters] = useState('')

  useEffect(() => {
    const fetchCases = async () => {
      setLoading(true)
      const res = await fetch('/data/cases.json')
      const cases = await res.json()
      setCaseData(cases)
      setLoading(false)
    }

    fetchCases()
  }, [])
  
  const  handleFilterOnChange = val => setCaseFilters(val)

  return (
    <div>
      <Header caseData={caseData} caseFilters={caseFilters} handleFilterOnChange={handleFilterOnChange} />
      { !loading ? <Map caseData={caseData} caseFilters={caseFilters} /> : <Loader /> }
    </div>
  );
}

export default App;
