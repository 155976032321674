import { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import distinctColors from 'distinct-colors'
import LocationMarker from './LocationMarker'
import LocationInfoBox from './LocationInfoBox'
import { getLatLngCenter } from '../helpers/map'
const styles = require('./GoogleMapStyles.json')

const Map = ({ caseData, caseFilters, zoom }) => {
    const [locationInfo, setLocationInfo] = useState(null)

    // Set default map center
    let defaultCenterLatLng = [19.8563, 102.4955]
    if(caseData.length) {
        const latlng = getLatLngCenter(caseData.map(c => ([c.latitude, c.longitude])))
        defaultCenterLatLng = [latlng[0], latlng[1]]
    }
    const defaultCenter = { lat: defaultCenterLatLng[0], lng: defaultCenterLatLng[1] }

    // If location exists, update the center
    const center = locationInfo ? { lat: locationInfo.lat, lng: locationInfo.lng } : null

    const handleMarkerClick = (index, cs) => {
        if (locationInfo && locationInfo.id === index) {
            setLocationInfo(null)
        } else {
            setLocationInfo({ id: index, case: cs.case, date: cs.date, lat: cs.latitude, lng: cs.longitude })
        }
    }

    const handleClose = () => {
        setLocationInfo(null)
    }

    // Generate case color map
    const uniqueCases = [...new Set(caseData.map(c => c.case))]
    const palette = distinctColors({ count: uniqueCases.length })
    const chromaMap = {}
    uniqueCases.forEach((c, index) => { chromaMap[c] = palette[index] })

    const markers = caseData
        .filter(c => caseFilters.length ? caseFilters.includes(c.case) : true)
        .map((cs, index) => {
        return <LocationMarker
            key={index}
            lat={cs.latitude}
            lng={cs.longitude}
            chroma={chromaMap[cs.case]}
            isSelected={locationInfo && locationInfo.id === index}
            onClick={() => handleMarkerClick(index, cs)}
        />
    })

    return (
        <div className="map">
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
                center={ center }
                defaultCenter={ defaultCenter }
                defaultZoom={ zoom }
                options={{
                    disableDefaultUI: true, // disable default map UI
                    draggable: true, // make map draggable
                    scaleControl: true, // allow scale controle
                    scrollwheel: true, // allow scroll wheel
                    gestureHandling: 'greedy',
                    styles: styles // change default map styles
                }}
            >
                {markers}
            </GoogleMapReact>
            {locationInfo && <LocationInfoBox info={locationInfo} handleClose={handleClose} />}
        </div>
    )
}

Map.defaultProps = {
    zoom: 11
}

export default Map
